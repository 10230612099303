




















































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import FiltersListHelper from '@/views/chat/filters/FiltersListHelper'
import FilterCard from '@/views/chat/filters/FilterCard.vue'
import { FilterConfig, FilterTypes } from '@/views/chat/filters/FilterTypes'
import { InputSetups } from '../../../mixins/input-setups'
import { MIGRATE_FILTER_MODULES } from "@/includes/logic/Modules/MIGRATE_FILTER_MODULES";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import ModuleBuildersFactory from "@/includes/logic/Modules/Factories/ModuleBuildersFactory";
import { successNotification } from "@/includes/NotificationService";

import { Item } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ListWithPreview from 'piramis-base-components/src/components/ListWithPreviews/ListWithPreviews.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { isEmpty } from 'lodash'

@Component({
  components: {
    FilterCard,
    CenteredColumnLayout,
    PageTitle,
    ListWithPreview,
    EmptyData
  },
  data() {
    return {
      isEmpty
    }
  }
})
export default class New_filters extends Mixins<UseFields, InputSetups, FiltersListHelper>(UseFields, InputSetups, FiltersListHelper) {
  isAddFilterPopupActive = false

  updateListKey = 1

  selectFromDisabledList(item: Item): void {
    if (MIGRATE_FILTER_MODULES.includes(item.key as FilterTypes)) {
      this.gotoSelectedFilter(item.key as FilterTypes)
    } else {
      this.$store.commit('setObjectField', {
        object: this,
        objectPath: `$store.state.chatState.chat.config.${ item.key }.enabled`,
        value: true,
      })

      this.isAddFilterPopupActive = false

      this.gotoSelectedFilter(item.key as FilterTypes)
    }
  }

  removeFilter(filterItem: FilterConfig): void {
    if (MIGRATE_FILTER_MODULES.includes(filterItem.type) && ModuleManagerState) {
      const filter = ModuleManagerState.modules.find(m => m instanceof FilterModule && m.snakeCaseFilterType === filterItem.type)

      if (filter) {
        const builder = ModuleBuildersFactory.getBuilder(filter.type)

        if (builder) {
          builder.setBuilderModel(filter)

          ModuleManagerState.removeModule(builder)
            .then((res) => {
              if (res) {
                successNotification()

                this.updateListKey += 1
              }
            })
        }
      }
    } else {
      this.$store.commit('setObjectField', {
        object: this,
        objectPath: `$store.state.chatState.chat.config.${ filterItem.type }.enabled`,
        value: false,
      })

      this.$store.dispatch('saveChatConfig')
        .then(() => {
          this.updateListKey += 1
        })
    }
  }

  gotoSelectedFilter(filterType: FilterTypes): void {
    this.$router.push({
      name: 'filter',
      params: {
        [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id,
        filterName: filterType.toString()
      }
    })
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}

